<template>
  <div class="main-map">
    <component
      ref="map"
      v-bind:is="'GoogleMap'"
      :source="source"
      :pickupDraggable="pickupDraggable"
      :pickup="pickup"
      :destination="destination"
      :driverLatLng="driverLatLng"
      :waypoints="waypoints"
      :status="status"
      :markersDisabled="markersDisabled"
      :defaultCenterLatLng="centerLatLng"
      @pickupUpdate="pickupUpdate"
      :taxiIconUrl="taxiIconUrl"
      :colorPickupMarker="colorPickupMarker"
      :colorDestinationMarker="colorDestinationMarker"
      :colorWaypointMarker="colorWaypointMarker"
    ></component>
  </div>
</template>

<script>
export default {
  name: 'Map',
  components: {
    LeafletMap: () => import('./LeafletMap.vue'),
    GoogleMap: () => import('./GoogleMap.vue'),
  },
  data() {
    return {
      taxiIconUrl: '/img/pin-taxi.svg',
      colorPickupMarker: '#000000',
      colorDestinationMarker: '#247DFF',
      colorWaypointMarker: '#FFFFFF',
    };
  },
  props: {
    pickup: Object,
    waypoints: Array,
    destination: Object,
    centerLatLng: Object,
    driverLatLng: Object,
    pickupDraggable: Boolean,
    source: String,
    status: String,
    markersDisabled: Boolean,
  },

  watch: {
    pickup(val) {
      if (!val) return;

      this.$refs.map.$forceUpdate();
    },
  },
  methods: {
    pickupUpdate(e) {
      this.$emit('pickupUpdate', e);
    },
  },
};
</script>

<style>
.main-map {
  height: 100%;
  /* min-height: 100vh; */
  width: 100%;
}
</style>
