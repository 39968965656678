<template>
  <v-app>
    <html-head :inner-title="this.companyName" />
    <v-main v-if="cookiesEnabled && bookingChannelConfigured" id="app-router-view">
      <v-container fluid class="app-container ma-0 pa-0" :class="{ 'auto-height': !noAutoHeightContainer, 'fill-height': noAutoHeightContainer }">
          <notifications position="top right" :duration=-1 />
          <navigation v-if="!noNavigationRoute" @drawer="updateDrawer" :activeBookings="getActiveBookings"></navigation>
          <span v-if="getActiveBookings > 0 && !$vuetify.breakpoint.mobile" :class="{ 'collapsed': isCollapssed }" class="bookings-bagde">{{ getActiveBookings }}</span>
          <global-errors></global-errors>
          <router-view></router-view>
        </v-container>
        <version v-if="showVersion"></version>
      <cookie-consent href="https://www.icabbi.com/privacy-statement/">
        <template
          slot="message"
        >
         <span class="cookie-consent-message">
            {{ $t('cookieBanner.message') }}
            <a class="cookie-consent-link" :href="privacyPolicyLink" target="_blank">{{ $t('cookieBanner.link') }}</a>
         </span>
        </template>
        <template
          slot="button"
        >
          <button class="cookie-consent-compliance">{{ $t('cookieBanner.button')}}</button>
        </template>
      </cookie-consent>
    </v-main>
    <booking-channel-missing v-if="cookiesEnabled && !bookingChannelConfigured"></booking-channel-missing>
    <cookie-disabled v-if="!cookiesEnabled"></cookie-disabled>
  </v-app>
</template>

<script>
import Vue from 'vue';
import * as GmapVue from 'gmap-vue';
import { mapGetters } from 'vuex';
import i18n from '@/i18nInstance';
import Navigation from './views/navigation/Navigation.vue';
import CookieDisabled from './views/alternate/CookieDisabled.vue';
import BookingChannelMissing from './views/alternate/BookingChannelMissing.vue';
import GlobalErrors from './views/shared/errors/GlobalErrors.vue';
import Version from './components/Version.vue';

export default {
  name: 'app',
  components: { GlobalErrors, Navigation, CookieDisabled, BookingChannelMissing, Version },
  data: () => ({
    cookiesEnabled: false,
    bookingChannelConfigured: false,
    isCollapssed: false,
  }),
  methods: {
    updateDrawer(val) {
      this.isCollapssed = val;
    },
  },
  beforeMount() {
    this.cookiesEnabled = navigator.cookieEnabled;

    if (this.cookiesEnabled && window.bookingChannelConfig) {
      this.bookingChannelConfigured = true;
      this.$store.commit('bookingChannel/setBookingChannelConfig', window.bookingChannelConfig);
    }

    if (this.maps && this.maps.name === 'googleMaps') {
      Vue.use(GmapVue, {
        load: {
          key: this.accessToken,
        },
      });
    }

    if (this.$vuetify) {
      if (this.primaryColor) {
        this.$vuetify.theme.primary = this.primaryColor;
        this.$vuetify.theme.themes.light.primary = this.primaryColor;
      }
      if (this.primaryFontColor) {
        this.$vuetify.theme.themes.light.primaryFont = this.primaryFontColor;
        this.$vuetify.theme.themes.dark.primaryFont = this.primaryFontColor;
        this.$vuetify.theme.primaryFont = this.primaryFontColor;
      }
    }
  },
  watch: {
    user: {
      handler() {
        if (!this.user.email && this.$router.currentRoute.path !== '/user-profile') {
          this.$notify({
            title: i18n.t('errors.userProfile.emailNotSetTitle'),
            text: i18n.t('errors.userProfile.emailNotSet'),
            type: 'warn',
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      companyName: 'bookingChannel/name',
      primaryColor: 'bookingChannel/primaryColor',
      primaryFontColor: 'bookingChannel/primaryFontColor',
      getBookings: 'bookings/bookings',
      user: 'userProfile/user',
      maps: 'bookingChannel/maps',
      accessToken: 'bookingChannel/mapAccessToken',
    }),
    noNavigationRoute() {
      return [
        'oauth-callback',
        'business-accounts',
        'get-started',
        'on-boarding',
        'email-confirm',
        'email-login-redirect',
        'shared-ride-tracking',
      ].indexOf(this.$route.name) >= 0;
    },
    noAutoHeightContainer() {
      const noAutoHeightRoutes = [
        'book',
        'ride-tracking',
      ];
      return noAutoHeightRoutes.includes(this.$route.name);
    },
    ...mapGetters({
      privacyPolicyLinks: 'bookingChannel/privacyPolicyLinks',
    }),
    privacyPolicyLink() {
      if (!this.privacyPolicyLinks) return '#';
      return (this.privacyPolicyLinks[this.$i18n.locale] || this.privacyPolicyLinks.en);
    },
    showVersion() {
      if (this.$route && this.$route.meta && this.$route.meta.hasVersion === false) {
        return false;
      }
      return true;
    },
    getActiveBookings() {
      return this.getBookings.filter(el => el.status === 'NEW').length;
    },
  },
};
</script>

<style lang="scss">
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent";
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-bottom";
@import "./node_modules/vue-cookieconsent-component/src/scss/cookie-consent-transition";


body {
  font-family: Inter;
}

#app-router-view {
  height: 100vh;

  @media only screen and (max-width: 960px) {
    padding-top: 0px !important;
  }
}

.app-container {
  padding: 0 0 20px 0;
  align-items: flex-start !important;
  background-color: #f2f2f2;
  min-height: 100%;
}
auto-height {
  height: auto;
}
.cookie-consent-link {
  color: #247DFF;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
  background: #f8f8f8;

  &:hover {
    background: #f8f8f8 !important;
  }
}
.theme--light.v-application {
  color: rgba(0,0,0,0.87);
}

input:focus {
  border-bottom: none!important;
  box-shadow: none!important;
  outline: none;
}

.v-input__slot {
  border-radius: 4px;
}

.v-text-field__details {
  margin-bottom: 2px !important;
}

.v-dialog > .v-card > .v-card__title {
  background-color: black;
  text-align: center !important;
  font-size: large;
  color: #FFFFFF;
  text-transform: uppercase;
}

.is-full-page  {
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  vertical-align: center;
  align-content: center;
  z-index: 1;
  opacity: 0.5;
  background-color: #7f7f7f;
}

.vld-icon {
  text-align: center;
  line-height: 500px;
}

.v-icon {
  vertical-align: middle !important;
}

.v-dialog > .v-card > .v-card__title > span {
  width: 100%;
}

.v-btn__content {
  letter-spacing: normal !important;
}

.collapsed {
  top: 28vh !important;
  left: 55px !important;
}

@media only screen and (max-width: 960px) {
  .mobile-column-reverse {
    flex-flow: column-reverse !important;
  }

  .booking-menu-container {
    top: 10% !important;
  }

  .cookie-card {
    margin: 20px;
  }
}

.bookings-bagde {
  position: fixed;
  top: 28.5vh;
  left: 160px;
  z-index: 100;
  padding: 5px;
  background: #47D061;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.005em;

  color: #FFFFFF;
}

</style>
